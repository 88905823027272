/* eslint-disable no-param-reassign */
import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import { baseResponse } from 'interfaces/BaseResponse';
import { errorRedux } from 'interfaces/redux';
import { resendContract, signer, signerResponse } from 'interfaces/Signer';
import { patchProposalResendNotification, postLogout, patchProposalResendContract, signinPost } from './service';

export const signin = createAsyncThunk<signerResponse, signer, { rejectValue: errorRedux }>(
  'signer/signin',
  async (payload, { rejectWithValue }) => {
    try {
      const data = await signinPost(payload);
      return data;
    } catch (error) {
      const err = error as AxiosError<errorRedux>;
      return rejectWithValue(err.response?.data as errorRedux);
    }
  },
);

export const resendContractType = createAsyncThunk<baseResponse, resendContract, { rejectValue: errorRedux }>(
  'signer/resend',
  async (resendContractParams, { rejectWithValue }) => {
    try {
      const data = await patchProposalResendContract(resendContractParams);
      return data;
    } catch (error) {
      const err = error as AxiosError<errorRedux>;
      return rejectWithValue(err.response?.data as errorRedux);
    }
  },
);

export const resendNotification = createAsyncThunk<baseResponse, { identifier: string }, { rejectValue: errorRedux }>(
  'signer/resendNotification',
  async ({ identifier }, { rejectWithValue }) => {
    try {
      const data = await patchProposalResendNotification({ identifier });
      return data;
    } catch (error) {
      const err = error as AxiosError<errorRedux>;
      return rejectWithValue(err.response?.data as errorRedux);
    }
  },
);

interface ISignerReducer extends errorRedux {
  signed: boolean;
  signin: signerResponse;
}

const initialState: ISignerReducer = {
  status: 'idle',
  signed: false,
  type: '',
  message: '',
  signin: {
    status: '',
    message: '',
    user: '',
    token: '',
    currentRefreshToken: '',
    data: [],
  },
};

export const clearSignin = createAsyncThunk('users/logout', async (_, { rejectWithValue }) => {
  try {
    const data = await postLogout();
    return data;
  } catch (error) {
    const err = error as AxiosError<errorRedux>;
    return rejectWithValue(err.response?.data as errorRedux);
  }
});

export const signerReducer = createSlice({
  name: 'signer',
  initialState,
  reducers: {
    RESET: (state, action) => {
      return initialState;
    },
    setRefreshToken: (state, action: PayloadAction<{ token: string; currentRefreshToken: string }>) => {
      state.status = 'idle';
      // state.signed = false;
      state.signin.token = action.payload.token;
      state.signin.currentRefreshToken = action.payload.currentRefreshToken;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(signin.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(signin.fulfilled, (state, action) => {
        state.status = 'completed';
        state.signed = true;
        state.signin = action.payload;
      })
      .addCase(signin.rejected, (state) => {
        state.status = 'failed';
        state.signed = true;
      })
      .addCase(clearSignin.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(clearSignin.fulfilled, (state, action) => {
        state.status = 'completed';
        state.signed = false;
        state.signin = { status: '', message: '', token: '', currentRefreshToken: '', user: '', data: [] };
      })
      .addCase(clearSignin.rejected, (state) => {
        state.status = 'failed';
        state.signed = false;
      });
  },
});

export const { setRefreshToken } = signerReducer.actions;
export default signerReducer.reducer;
